import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import '../App.css';
import { useTranslation } from 'react-i18next';
import { IoIosEye, IoIosEyeOff } from 'react-icons/io';
import logo from '../logo_generalitat_blanc.svg';
import FileUpload from '../utils/FileUpload';
import DocumentList from '../utils/DocumentList';
import { Dropdown, Menu } from 'semantic-ui-react';
import i18n from '../i18n';
import 'semantic-ui-css/semantic.min.css';



const ConsultaDocumentacio: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const location = useLocation();
  const [language, setLanguage] = useState<string>(i18n.language);

  const { t } = useTranslation();

  // Obtengo el startMeeting de location.state
  const startMeeting = location.state?.meeting?.startMeeting;
  const token = location.state?.meeting?.token;
  const meetingId = location.state?.meeting?.meetingId;

  // Parsear startMeeting si está definido para obtener fecha y hora
  let formattedDate = '';
  let formattedTime = '';
  if (startMeeting) {
    const currentDate = new Date(startMeeting);
    formattedDate = `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`;

    const hours = currentDate.getHours().toString().padStart(2, '0');
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');
    formattedTime = `${hours}:${minutes}`;
  }

  const [keyCounter, setKeyCounter] = useState<number>(0); // Contador para actualizar el DocumentList

  const handleFileUploaded = () => {
    setKeyCounter(keyCounter + 1); // Incrementa el contador cada vez que se sube un archivo
  };




  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage && storedLanguage !== language) {
      i18n.changeLanguage(storedLanguage);
      setLanguage(storedLanguage);
    }
  }, [language, i18n]);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
    setLanguage(lng);
  };

  const dropdownRef = useRef(null);

  return (
    <div>
      <div className="header no-select" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px', userSelect:'none'}}>

        <img className="logoGentcat" alt="gencat" src={logo} />
        <Dropdown
          ref={dropdownRef}
          item
          text={`${language.toUpperCase()}`}
          style={{ padding: '0 10px', color: "white"}}
        >
          <Dropdown.Menu style={{ minWidth: '3em' }}>
            <Dropdown.Item onClick={() => changeLanguage('ca')}>CA</Dropdown.Item>
            <Dropdown.Item onClick={() => changeLanguage('es')}>ES</Dropdown.Item>
            <Dropdown.Item onClick={() => changeLanguage('en')}>EN</Dropdown.Item>
            <Dropdown.Item onClick={() => changeLanguage('oc')}>OC</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <div className="App">
        <header className="App-header">
          <h1 className='titulo'>{t('titulo')}</h1>
          <h4 className='subtitulo'>{t('consultaDocumentacio.subtitulo')}</h4>
          <p className='infoDocumentacio'>
            {t('consultaDocumentacio.documentacio')}
            {meetingId && <span style={{ color: 'red' }}> {meetingId}</span>}
          </p>

          <div className="dateTimeContainer">
            {startMeeting && (
              <p><strong>{t('consultaDocumentacio.dia')}: </strong>{formattedDate} <strong>{t('consultaDocumentacio.hora')}: </strong>{formattedTime}</p>
            )}
          </div>

          <div className="fileInputContainer">
            <p className='tituloSelectFile'>{t('consultaDocumentacio.document_adjunt')}</p>
            <div className="fileButtonContainer">
              <FileUpload token={token} meetingId={meetingId} onFileUploaded={handleFileUploaded} />
              <DocumentList key={keyCounter} token={token} meetingId={meetingId} />
            </div>
          </div>
        </header>
      </div>
    </div>
  );
};

export default ConsultaDocumentacio;

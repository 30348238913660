import React, { useState, useRef, ChangeEvent } from 'react';
import '../App.css';
import { useTranslation } from 'react-i18next';
import logo from '../logo_generalitat_blanc.svg';

const App: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const { t } = useTranslation();

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  return (
    <div className="header">
      <img className="logoGentcat" alt="gencat" src={logo} /> 
      <div className="App">
        <header className="App-header">
          <h1 className='titulo'>{t('titulo')}</h1>
          <h4 className='subtitulo'>{t('accesAplicacio.subtitulo')}</h4>
          <p className='infoDocumentacio'>{t('accesAplicacio.no_disponible')}</p>
        </header>
      </div>
    </div>
  );
};

export default App;

import React, { useState, useEffect, useRef } from 'react';
import { IoIosEye, IoIosEyeOff } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import '../App.css';
import { validarOtp, enviarOTP } from '../services/services.service';
import { Dropdown } from 'semantic-ui-react';
import { changeLanguage } from 'i18next';
import i18n from '../i18n';
import logo from '../logo_generalitat_blanc.svg';

const AplicacioCarrega: React.FC = () => {
  const [otp, setOtp] = useState('');
  const [shown, setShown] = useState(false);
  const [errorMessage, setErrorMessage] = useState<[string, string]>(['', '']);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [language, setLanguage] = useState<string>(i18n.language);

  useEffect(() => {
    const fetchData = async () => {
      const params: any = location;
      const meetingId = params['pathname'].split('/')[1] || 'defaultMeetingId'; // Obtener meetingId de la URL
      const isDocumentationOtp = true;

      try {
        const response = await enviarOTP(meetingId, language, isDocumentationOtp);
        if (response) {
          updateErrorMessage(response.code);
        } else {
          console.error('Error en la llamada de enviar OTP');
        }
      } catch (error) {
        console.error('Error en la llamada de enviar OTP:', error);
      }
    };

    fetchData();
  }, [location.search, navigate]);


  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage && storedLanguage !== language) {
      i18n.changeLanguage(storedLanguage);
      setLanguage(storedLanguage);
    }
  }, [language]);

  useEffect(() => {
    if (errorMessage[0]) {
      const translatedMessage = t(`error_codes.${errorMessage[0]}`);
      setErrorMessage([errorMessage[0], translatedMessage]);
    }
  }, [errorMessage[0], t]);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
    setLanguage(lng);
  };


  const updateErrorMessage = (errorCode: string) => {
    if (!ignoreErros(errorMessage[0]) && errorCode) {
      const userMessage = t(`error_codes.${errorCode}`);
      setErrorMessage([errorCode, userMessage]);
    }
  };

  const ignoreErros = (errorCode: string): boolean => {
    switch (errorCode) {
      case 'DOCUMENTATIONNOTALLOWEDTIMEOUT':
      case 'INVALIDMEETING':
      case 'DOCUMENTATIONNOTALLOWED':
        return true;
      default:
        return false;
    }
  };

  const switchShown = () => setShown(!shown);

  const handleOtpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setOtp(value);
  };

  const handleGetOtp = async () => {
    updateErrorMessage('');
    try {
      const params: any = location;
      const meetingId = params['pathname'].split('/')[1] || 'defaultMeetingId'; // Obtener meetingId de la URL

      if (!otp) {
        updateErrorMessage('EMPTYOTP');
        return; // Detener la ejecución si el campo OTP está vacío
      }

      const result = await validarOtp(otp, meetingId);

      if (result?.meetingStatus == "DOCUMENTATIONNOTALLOWEDTIMEOUT") {
        updateErrorMessage('DOCUMENTATIONNOTALLOWEDTIMEOUT');
      }
      if (result !== null) {
        if (!meetingId && result.code === 'INVALIDMEETING') {
          updateErrorMessage('INVALIDMEETING');
          throw new Error('No se proporcionó un meetingId válido');
        }

        if (result.token) {

          navigate('/ConsultaDocumentacio', { state: { meeting: result } }); // Redirigir a ConsultaDocumentacio si OTP es válido
        }
        else if (result.code === "INVALIDOTP") {
          updateErrorMessage('INVALIDOTP');
        }
        else {
          updateErrorMessage('');
        }
      } else {
        updateErrorMessage('VALIDATIONERROR');
      }
    } catch (error) {
      console.error('Error al validar el codigo:', error);
      updateErrorMessage("VALIDATIONERROR");
    }

  };
  
  const dropdownRef = useRef(null);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleGetOtp();
    }
  };

  return (
    <div>
      <div className="header no-select" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px', userSelect: 'none' }}>

        <img className="logoGentcat" alt="gencat" src={logo} />
        <Dropdown
          ref={dropdownRef}
          item
          text={`${language.toUpperCase()}`}
          style={{ padding: '0 10px', color: "white" }}
        >
          <Dropdown.Menu style={{ minWidth: '3em' }}>
            <Dropdown.Item onClick={() => changeLanguage('ca')}>CA</Dropdown.Item>
            <Dropdown.Item onClick={() => changeLanguage('es')}>ES</Dropdown.Item>
            <Dropdown.Item onClick={() => changeLanguage('en')}>EN</Dropdown.Item>
            <Dropdown.Item onClick={() => changeLanguage('oc')}>OC</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="App">
        <header className="App-header">
          <h1 className='titulo'>{t('titulo')}</h1>

          <div className="inputWithIcon">
            <input
              type={shown ? 'text' : 'password'}
              value={otp}
              onChange={handleOtpChange}
              onKeyDown={handleKeyDown}
              placeholder={t('aplicacioCarrega.PIN')}
              className="cuadroTexto"
              maxLength={8}
            />
            <button onClick={switchShown} className='botonMostrarPin'>
              {shown ? <IoIosEye /> : <IoIosEyeOff />}
            </button>
          </div>

          {errorMessage && < p className="errorMessage">{errorMessage[1]}</p>}
          <button onClick={handleGetOtp} className='botonAplicacioCarrega'>
            {t('aplicacioCarrega.botonAplicacioCarrega')}
          </button>
        </header>
      </div>

    </div>
  );
};

export default AplicacioCarrega;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fileUpload } from '../services/services.service';

interface FileUploadProps {
  meetingId: string;
  token: string;
  onFileUploaded: () => void; // Función para notificar al padre que se ha subido un archivo
}

const FileUpload: React.FC<FileUploadProps> = (props) => {
  const { t } = useTranslation();
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const [selectedFileName, setSelectedFileName] = useState<string>('');

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setSelectedFiles(files);
      setSelectedFileName(files[0].name); // Guarda el nombre del primer archivo seleccionado
    } else {
      setSelectedFiles(null);
      setSelectedFileName('');
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!selectedFiles || selectedFiles.length === 0) {
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append('files', selectedFiles[i]);
    }

    try {
      await fileUpload(formData, props.meetingId, props.token);
      setSelectedFiles(null);
      setSelectedFileName('');
      props.onFileUploaded();
    } catch (error) {
      console.error('Error uploading files:', error);
      // Manejar errores de carga de archivos aquí, si es necesario
    }
  };

  return (
    <form onSubmit={handleSubmit} className="buttonContainer">
      <label className="customFileUpload">
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
        {t('consultaDocumentacio.seleccionar_arxiu')}
      </label>
      {selectedFileName && (
        <p className="selectedFileName">{selectedFileName}</p>
      )}
      <button type="submit" className="selectFileButton">
        {t('consultaDocumentacio.pujar_arxiu')}
      </button>
    </form>
  );
};

export default FileUpload;

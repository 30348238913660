import React, { useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AplicacioCarrega from "./components/AplicacioCarrega";
import ConsultaDocumentacio from './components/ConsultaDocumentacio';
import AccesAplicacio from './components/AccesAplicacio';

import './App.css';

function App() {

  return (
    <Router>
      <Routes>
        <Route path='/:id' element= {<AplicacioCarrega></AplicacioCarrega>}></Route>
        <Route path='/ConsultaDocumentacio' element= {<ConsultaDocumentacio></ConsultaDocumentacio>}></Route>
        <Route path='/AccesAplicacio' element= {<AccesAplicacio></AccesAplicacio>}></Route>
      </Routes>
    </Router>
  )
}

export default App;
export const enviarOTP = async (meetingId: string, language: string, isDocumentationOtp: boolean) => {
  const url = `${process.env.REACT_APP_CITIZENSERVICES_BASE_URL}${process.env.REACT_APP_CITIZENSERVICES_PARTIAL_URL_AUTHMEET}`;
  const payload = {
    meetingId,
    language,
    isDocumentationOtp
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });

    const data = await response.json();

    if (response.ok) {
      return data.Value;
    } else if (response.status === 400) {
      console.error('Error code:', data.Value.code);
      console.error('Internal message:', data.Value.internalMessage);
    } else {
      console.error('Unexpected error:', data);
    }
  } catch (error) {
    console.error('Error:', error);
  }

  return null;
};



export const validarOtp = async (otp: string, meetingId: string) => {
  const url = `${process.env.REACT_APP_CITIZENSERVICES_BASE_URL}${process.env.REACT_APP_CITIZENSERVICES_PARTIAL_URL_AUTHMEET}`;
  const payload = {
    meetingId,
    language: 'ca',
    OTP: otp,
    isDocumentationOtp: true
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });

    const data = await response.json();
    if (response.ok) {
      console.log("y entrando");
      return {
        isValid: true,
        token: data.Value.Token,
        meetingStatus: data.Value.MeetingStatus,
        startMeeting: data.Value.StartMeeting,
        meetingId: meetingId
      };
    }
    else if (response.status === 400 || response.status === 404) {
      return data.Value;
    }
  } catch (error) {
    console.error('Error al validar OTP:', error);
  }

  return null;
};

export const fileUpload = async (formData: FormData, meetingId: string, token: string) => {

  try {
    const response = await fetch(`${process.env.REACT_APP_SHAREDFILE_BASE_URL}${process.env.REACT_APP_SHAREDFILE_PARTIAL_URL_UPDOCUMENTS}` + meetingId, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + token
      },
      body: formData
    });

    const data = await response.json();
    if (response.ok) {
      return {
        isValid: true,
        token: data.Value.Token,
        meetingStatus: data.Value.MeetingStatus,
        startMeeting: data.Value.StartMeeting
      };
    } else if (response.status === 400 || response.status === 404) {
      console.error('Error:', data.Value.internalMessage);
      return { isValid: false };
    }
  } catch (error) {
    console.error('Error uploading file', error);
  }
};
